// window.$ = $;

window.alert = function(message, type = 'danger') {
    $('main').prepend(`
        <div class="alert alert-${type} alert-dismissible" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">&times;</button>
            ${message}
        </div>
    `);
};

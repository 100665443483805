require('bs-custom-file-input').init();

$('.needs-validation').on('submit', function (event) {
    $(this).addClass('was-validated');
    if (!this.checkValidity()) {
        event.preventDefault();
        event.stopImmediatePropagation();
        $(this).trigger('submit-invalid');
    }
});

export function getRecaptcha() {
    let url = new URL(document.querySelector('script[src^="https://www.google.com/recaptcha/api.js"]').src);
    let params = new URLSearchParams(url.search);
    return grecaptcha.execute(params.get('render'));
};

$.fn.ajaxInvalidFeedback = function(options) {
    return this.each(function() {
        let object = $(this).data('ajaxInvalidFeedback');
        if (typeof options == 'object') {
            $(this).addClass('has-invalid-feedback-ajax').after(`
                <div class='invalid-feedback invalid-feedback-ajax d-block'>${options.error}</div>
            `);
            let destroyHandler = () => $(this).ajaxInvalidFeedback('destroy');
            $(this).on('change input', destroyHandler);
            $(this.form).on('submit', destroyHandler);
            $(this).data('ajaxInvalidFeedback', {destroyHandler});
        } else if (options === 'destroy') {
            $(this).off('change input', object.destroyHandler).removeClass('has-invalid-feedback-ajax').siblings('.invalid-feedback-ajax').remove();
            $(this.form).off('submit', object.destroyHandler);
            $(this).data('ajaxInvalidFeedback', null);
        }
    });
};

export function processFormErrors(errors) {
    for (let fieldName in errors) {
        if (fieldName == '') {
            for (let error of errors[fieldName]) {
                alert(error);
            }
        } else {
            let error = errors[fieldName].join('<br>');
            $(`[name="${fieldName}"]`).ajaxInvalidFeedback({error: error});
        }
    }
}

$('.ajax-form').on('submit', async function(event) {
    event.preventDefault();
    $(this.elements).filter('[type=submit]').prop('disabled', true);
    let formData = new FormData(this);
    if ($(this).data('recaptcha')) {
        formData.set('recaptcha', await getRecaptcha());
    }
    try {
        var data = await $.ajax({
            type: 'post',
            url: this.action,
            data: formData,
            processData: false,
            contentType: false,
        });
    } catch (xhr) {
        let errorEvent = $.Event('submit-error');
        $(this).trigger(errorEvent, xhr);
        if (!errorEvent.isDefaultPrevented()) {
            if (xhr.responseJSON && xhr.responseJSON.errors) {
                processFormErrors(xhr.responseJSON.errors);
            } else {
                console.log("Wystąpił nieznany błąd, spróbuj ponownie później.");
            }
        }
        return;
    } finally {
        $(this.elements).filter('[type=submit]').prop('disabled', false);
    }
    let successEvent = $.Event('submit-success');
    $(this).trigger(successEvent, data);
    if (!successEvent.isDefaultPrevented()) {
		this.reset();
		$(this.elements).prop('disabled', false);
        $(this).removeClass('was-validated');
    }
});

import * as bootstrap from 'bootstrap5';

$('#personal_data_billTypeCheckbox').on('change', function() {
	let checked = $(this).prop('checked');
	if (checked) {
		$('#personal_data_billType').attr('value', 'Faktura');
	} else {
		$('#personal_data_billType').attr('value', 'Paragon');
	}
	$('#personal_data_billPos').prop('disabled', checked);
	$('#personal_data_billPos').prop('required', !checked);
	$('#personal_data_billPos').val('');
});

$('#personal_data_pesel_checkbox').on('change', function() {
	let checked = $(this).prop('checked');
	$('#personal_data_pesel').prop('disabled', checked);
	$('#personal_data_pesel').prop('required', !checked);
	$('#personal_data_pesel').val('');
	$('.fieldset-pesel').toggleClass('d-none').prop('disabled', !checked);
});

$('input.iban-check').each(function() {
	$(this).on('change', function() {
		let checked = $(this).val();
		$('input.iban-check').prop('checked', false);
		$(this).prop('checked', true);
		$('fieldset.fieldset-iban-record-yes').toggleClass('d-none').prop('disabled', (i, v) => !v);
		$('fieldset.fieldset-iban-record-no').toggleClass('d-none').prop('disabled', (i, v) => !v);
	});
});

function apiPersonalData(dataUser, modal) {
	$(`${modal} #data`).empty();
	dataUser.firstname && dataUser.lastname ? $(`${modal} #data`).append(`<p>Imię i nazwisko uczestnika: ${dataUser.firstname} ${dataUser.lastname}</p>`) : null;
	dataUser.email ? $(`${modal} #data`).append(`<p>E-mail uczestnika: ${dataUser.email}</p>`) : null;
	if (dataUser.billNr) {
		dataUser.billNr && dataUser.billPos ? $(`${modal} #data`).append(`<p>Numer paragonu: ${dataUser.billNr}</p>`) : $(`${modal} #data`).append(`<p>Numer faktury: ${dataUser.billNr}</p>`);
	}
	if (dataUser.billDate) {
		dataUser.billDate && dataUser.billPos ? $(`${modal} #data`).append(`<p>Data paragonu: ${dataUser.billDate}</p>`) :  $(`${modal} #data`).append(`<p>Data faktury: ${dataUser.billDate}</p>`);
	}
	dataUser.billNip ? $(`${modal} #data`).append(`<p>NIP sklepu: ${dataUser.billNip}</p>`) : null;
	dataUser.billPos ? $(`${modal} #data`).append(`<p>Unikalny numer kasy fiskalnej: ${dataUser.billPos}</p>`) : null;
	dataUser.birthDay ? $(`${modal} #data`).append(`<p>Data urodzenia: ${dataUser.birthDay}</p>`) : null;
	dataUser.address ? $(`${modal} #data`).append(`<p>Adres: ${dataUser.address}</p>`) : null;
	dataUser.citizenship ? $(`${modal} #data`).append(`<p>Narodowość: ${dataUser.citizenship}</p>`) : null;
	dataUser.pesel ? $(`${modal} #data`).append(`<p>Pesel: ${dataUser.pesel}</p>`) : null;
	dataUser.identityDocumentNr ? $(`${modal} #data`).append(`<p>Numer dowodu osobistego: ${dataUser.identityDocumentNr}</p>`) : null;
	dataUser.identityDocumentType ? $(`${modal} #data`).append(`<p>Typ dowodu osobistego: ${dataUser.identityDocumentType}</p>`) : null;
	dataUser.iban ? $(`${modal} #data`).append(`<p>Numer konta bankowego: ${dataUser.iban}</p>`) : null;
	dataUser.creditAccountNumber ? $(`${modal} #data`).append(`<p>Numer konta bankowego: ${dataUser.creditAccountNumber}</p>`) : null;
	// dataUser.uuid ? $(`${modal} #data`).append(`<p>UIC: ${dataUser.uuid}</p>`) : null;
	dataUser.pdf ? $(`${modal} #print`).attr('href', `${dataUser.pdf}`) : null;
	if (dataUser.confirmationUrl) {
		$(`${modal} hr`).removeClass(`d-none`);
		$(`${modal} img#confirmation`).removeClass(`d-none`);
		$(`${modal} img#confirmation`).attr(`src`, `${dataUser.confirmationUrl}`);
	}
	if (dataUser.paragonUrl) {
		$(`${modal} hr`).removeClass(`d-none`);
		$(`${modal} img#paragon`).removeClass(`d-none`);
		$(`${modal} img#paragon`).attr(`src`, `${dataUser.paragonUrl}`);
	}
	if (dataUser.agreementUrl) {
		$(`${modal} hr`).removeClass(`d-none`);
		$(`${modal} img#agreement`).removeClass(`d-none`);
		$(`${modal} img#agreement`).attr(`src`, `${dataUser.agreementUrl}`);
	}
}

$('form#form-application').on('submit-success', function(event, data) {
	try {
		fetch(`/api/${data.uuid}.json`)
		.then(response => response.json())
		.then(dataUser => {
			let modal = `#modal-form-application-${data.prizeType}`
			apiPersonalData(dataUser, modal);
		});
	}
	catch (e) {
		console.log(e);
	} finally {
		let modal = new bootstrap.Modal(`#modal-form-application-${data.prizeType}`, {});
		modal.show();
	}
});

$('form#form-winner').on('submit-success', function(event, data) {
	try {
		fetch(`/api/${data.uuid}.json`)
		.then(response => response.json())
		.then(dataUser => {
			console.log(dataUser);
			apiPersonalData(dataUser, '#modal-form-winner');
		});
	}
	catch (e) {
		console.log(e);
	} finally {
		let modal = new bootstrap.Modal(`#modal-form-winner`, {});
		modal.show();

        $('.form-container').remove();
        $('.card').addClass('bg-white winner-info');
        $('.after-fill').removeClass('d-none');
        $('.bar').remove();
        $('.money').remove();
	}
});

$('form#testing').on('submit', function(event) {
	event.preventDefault();
	let uuid = $('input[name=uuid]').val();
	try {
		fetch(`/api/${uuid}.json`)
		.then(response => response.json())
		.then(dataUser => {
			console.log(dataUser);
			apiPersonalData(dataUser, '#modal-form-winner');
		});
	}
	catch (e) {
		console.log(e);
	} finally {
		let modal = new bootstrap.Modal(`#modal-form-winner`, {});
		modal.show();
	}
});

$('form#form-contact').on('submit-success', function(event, data) {
	let modal = new bootstrap.Modal(`#modal-form-contact`, {});
	modal.show();
});
import ReadSmore from 'read-smore';

// target all read more elements
const readMores = document.querySelectorAll('.js-read-smore');
const options = {
	moreText: 'więcej »',
	lessText: 'mniej «'
}

// Init
ReadSmore(readMores, options).init();